@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

html {
    overflow-x: hidden;
}

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}


body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 24px;
    color: #828282;
    font-weight: 400;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    /* overflow: hidden; */
}

a {
   color: #181818;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease
}

a:hover {
    text-decoration: none;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    color: #181818;
}

img {
    height: auto;
    max-width: 100%
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 10px;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 28px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}



label {
    font-size: 16px;
    font-weight: 700;
    color: #202020;
    display: inline-block;
}

.form-input,
select {
    background-color: #F4F4F5;
    border: 1px solid #e3e5e7;
    font-size: 16px;
    line-height: 20px;
    padding: 15px 25px;
    border-radius: 12px;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
}
.input-group{
    position:relative;
}
.input-group-text img{
    position: absolute;
    top: 60%;
    right: 40px; 
    transform: translateY(-50%);
    font-size: 16px; 
    color: #ccc;
    pointer-events: none; 
    background-color: #F4F4F5;
    /* right: 24px; */
}
.blood_group_arrow img{
    top: 71%;
    right: 5px;
}
.input-group-text > img.first-image {
    position: absolute;
    top: 70%;
    right: 25px; 
    transform: translateY(-50%);
    font-size: 16px; 
    color: #ccc;
    pointer-events: none; 
    background-color: #F4F4F5;
}
.input-group-text > img.first-image-error {
    position: absolute;
    top: 55%;
    right: 25px; 
    transform: translateY(-50%);
    font-size: 16px; 
    color: #ccc;
    pointer-events: none; 
    background-color: #F4F4F5;
}
.input-group-text > img.second-image {
    position: absolute;
    top: 69%;
    right: 25px; 
    transform: translateY(-50%);
    font-size: 16px; 
    color: #ccc;
    pointer-events: none; 
    background-color: #F4F4F5;
}
select {
    text-indent: 1px;
    /* background-image: url(images/arrow_down.svg); */
    background-repeat: no-repeat;
    background-position: right center;
    background-position: calc(100% - 15px) center
}
  

.dot-loader .dot {
    width: 6px;
    height: 6px;
    margin: 0 2px;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
    animation: dot-blink 1.2s infinite ease-in-out both;
  }
  .dot-loader .dot:nth-child(1) { animation-delay: -0.32s; }
  .dot-loader .dot:nth-child(2) { animation-delay: -0.16s; }
  
  @keyframes dot-blink {
    0%, 80%, 100% { transform: scale(0); }
    40% { transform: scale(1); }
  }
.button {
    color: #000;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    border-radius: 8px;
    background: #FDD100;
    border: 1px solid #FDD100;
    width: 100%;
}

.button:hover {
    background: #6191C7;
    border: 1px solid #6191C7;
    color: #fff;
}
.button:disabled {
    background: #F7F7F7;
    color: #878787;
    border: 1px solid #F7F7F7;
    cursor: not-allowed;
    pointer-events: none;
}
.row {
    display: flex;
    flex-wrap: wrap;
    /* border-bottom: 1px solid #E4E4E4; */
    /* padding-left: 30px; */
    padding-right: 30px;
}

.row>.col {
    flex: 1;
}
.grid-row.flex-row{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0;
    margin: 0 -15px;

}

.flex-row .col{
    flex: auto;
    padding: 0 15px;
    margin: 15px 0;
}
/* .row > .col.col-2,
.flex-row .col.col-2 {
    flex: 0 0 20%;
}
.row > .col.col-4,
.flex-row .col.col-4 {
    flex: 0 0 40%;
}
.row > .col.col-5,
.flex-row .col.col-5 {
    flex: 0 0 44%;
}
.flex-row .col.col-29{
    flex: 0 0 29%;
}
.flex-row .col.col-3{
    flex: 0 0 35%;
}

.flex-row .col.col-four-half{
    flex: 0 0 47%;
} */

.space-between {
    justify-content: space-between;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 25px 0;
}

.table-header h4 {
    color: #9A9A9A;
    margin: 0;
}

.table-header a {
    font-size: 18px;
    font-weight: 700;
    color: #486484;
    text-decoration: underline;
}

table.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
}
.landing-page {
    position: relative;
    width: 100%;
    height: 100vh;
  }
  
  .landing-page.blur {
    filter: blur(5px);
  }
  

table.table th {
    text-align: left;
    font-size: 18px;
    font-weight: 400px;
    color: black;
    padding: 15px 25px;
    background: #f4f4f5;
}

table.table td {
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    color: #6D6D6D;
    padding: 15px 25px;
}

table.table tbody tr {
    box-shadow: 0px 0px 15px rgba(163, 153, 163, 0.4);
    border-radius: 13px;
}

tr.disable {
    opacity: 0.4;
}
td.disable {
    opacity: 0.4;
}

.field-wrapper {
    display: flex;
    gap: 8px;
}

.responsive-table {
    width: 100%;
    overflow: auto;
}

.page-sidebar {
    position: fixed;
    max-width: 250px;
    top: 0;
    bottom: 0;
    overflow: hidden;
    padding: 25px;
    border-right: 1px solid #E4E4E4;
    z-index: 9;
    background: #FFF;
}

.mobile-logo {
    display: none
}

.navigation {
    padding-top: 50px;
}

.navigation ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.navigation ul li {
    margin: 0 0 10px;
}

.navigation ul a {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    display: inline-block;
    padding: 10px;
}

.navigation ul a>div {
    vertical-align: middle;
}



.page-wrapper {
    padding-left: 248px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 0;
}

.header-wrapper .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.search-box {
    max-width: 500px;
    flex: 0 0 50%;
    position: relative;
}

.search-box button {
    position: absolute;
    top: 19px;
    right: 25px;
    padding: 0;
    border: 0;
    background: transparent;
}

.search-box button img {
    width: 19px;
}

.header-right .account-link {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

.header-right .account-link li {
    margin-left: 24px;
    position: relative;
    font-size: 22px;
    color: #000;
}

.dashboard-info {
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
}

.welcome-user {
    max-width: 70%;
    flex: 0 0 100%;
}

.welcome-user>h2 {
    font-size: 28px;
    font-weight: 700;
    color: #486484;
    padding: 0 0 10px;
    text-transform: capitalize;
}

.welcome-user .col {
    margin-right: 30px;
}

.welcome-user .inner-col {
    color: #FFF;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 18px;
}

.welcome-user .inner-col p {
    margin: 0;
    font-weight: 700;
}

.upload-user-data .button {
    background: #6191C7;
    border-color: #6191C7;
    color: #FFF;
    padding: 15px 25px;
}

.upload-user-data {
   
}


.MuiMobileStepper-root {
    justify-content: center;
}

.signup-slider-wrapper .MuiMobileStepper-dotActive {
    background-color: #FFC727;
  }

.signup-slider-wrapper .MuiTypography-body1 {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    min-height: 60px;
}

.auth-links{
    max-width: 386px;
    width: 100%;
}

.signup-slider-wrapper .MuiMobileStepper-root{
    margin-top: 20px
}

.signup-slider-wrapper .MuiPaper-root {
    justify-content: center;
}

.slider-img-box{
    max-width: 346px;
    max-height: 212px;
    margin: 58px 0
}

.dashboard-chart {

}

.dashboard-chart-block {
    padding: 0 0 35px 0px;
    padding-right:30px;
    padding-left: 30px;
    
}

.dashboard-chart img {
    border-radius: 25px;
}

.graph-row {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    overflow: auto;
    grid-gap: 22px;
    padding: 35px 8px;
}
.error {
    border-color: red !important;
  }
  .error-message{
    color: red;
  }
.dashboard-table {
    -webkit-box-shadow: 0px 0px 20px -4px rgba(163, 153, 163, 1);
    -moz-box-shadow: 0px 0px 20px -4px rgba(163, 153, 163, 1);
    box-shadow: 0px 0px 20px -4px rgba(163, 153, 163, 1);
    border-radius: 25px;
    margin-right: 30px;
    margin-left: 30px;
}

.dashboard-table .responsive-table {
    padding: 0 25px;
    max-height: 260px;
    overflow: auto;
}

.user-table {
    margin: 0 -10px;
}

.user-table .responsive-table {
    padding: 0 10px;
}


/*----- EDIT PAGE -------*/
.user-action {
    position: relative;
    padding-left: 35px;
}

.user-action .bake {
    position: absolute;
    left: -5px;
    top: 3px;
}

.user-info h4 {
    color: #000000;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
}

.user-info label {
    font-size: 16px;
    font-weight: 700;
    color: #6D6D6D;
    margin: 0;
}

.edit-form-wrapper {
    padding-top: 30px;
    padding-bottom: 20px;
}

.edit-form-wrapper .block-title {
    padding: 20px 30px;
    background: #F3F4F5;
    margin: 0 0 30px;
    position: relative;
}

.edit-form-wrapper .block-title:before {
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
    bottom: 0;
    right: -50px;
    background: #F3F4F5;
    border-top: 1px solid #e3e5e7;
    border-bottom: 1px solid #e3e5e7;
}

.edit-form-wrapper .block-title h5 {
    color: #000000;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    position: relative;
}

.step-form {
    padding: 0 40px 0 40px;
}

.edit-form-wrapper .step-submit .control {
    display: flex;
    align-items: center;
    padding: 10px 40px;
}

.edit-form-wrapper .step-submit .button {
    margin-right: 15px;
    max-width: 100px;
    text-align: center;
    cursor: pointer;
}

.edit-form-wrapper .step-submit .button.outline {
    border: 1px solid #979797;
    background: transparent;
}

.edit-form-wrapper .step-submit .button.outline:hover {
    background: #6191C7;
    border-color: #6191C7;
}


/*------- PARTNER PAGE --------------*/
.partner-block {
    padding: 30px 0 0;
}

.partner-heading {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px;
    background: #F3F4F5;
    position: relative;
    height: 72px;
    align-items: center;
}

.partner-heading:before {
    content: "";
    /* position: absolute; */
    left: -50px;
    top: -20px;
    bottom: 0px;
    right: -50px;
    background: #F3F4F5;
    border-top: 1px solid #e3e5e7;
    border-bottom: 1px solid #e3e5e7;
    
}

.card {
    background-color: #F3F4F5;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: '100%';
    margin: 0 auto;
    align-self: center;
    margin-left: 30px;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
   }
   
   h2, h3 {
    color: #4a4a4a;
   }
   
   p {
    color: #777;
    line-height: 1.6;
   }

.templateHeader{
    padding-left: 30px;
}

.cardBtn{
    color: #000;
    border-radius: 8px;
    background: #FDD001;
    font-size: 20px;
    padding: 10px;
    border-color: #FDD001;
    border-width: 1px;
    width: 150px;
    align-items: center;
    justify-content: center;
}

.pending-cardBtn{
    color: #000;
    border-radius: 8px;
    background: #ffb0b0;
    font-size: 20px;
    padding: 10px;
    border-color: red;
    border-width: 1px;
    width: 150px;
    align-items: center;
    justify-content: center;
}

.partner-heading form {
    position: relative;
    z-index: 1;
}

.partner-filter-form {
    width: 100%;
    padding-right: 30px;
}

.partner-filter-form .form-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.partner-filter-form label {
    margin: 0; 
}

.partner-filter-form .control {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
}

.control {
    margin-left: 0px;
}

.partner-filter-form select {
    flex: 1;
    background-color: #FFF;
    border-color: #FFF;
    margin-right: 30px;
    padding: 8px 25px;
}

.partner-filter-form .button {
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    min-width: 150px;
    text-align: center;
    margin-right: 10px;
}

.partner-heading label {
    position: relative;
    z-index: 1;
}

.partner-heading .choose-dept {
    width: 100%;
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
}

.partner-heading .choose-dept label {
    margin: 0;
    padding-right: 10%;
}


.partner-filter-block {
    position: relative;
}

.partner-filter-data {
    padding-top: 30px;
    padding-right: 30px;
}

.list-view {
    overflow-y: auto;
}

.partner-filter-data>h5 {
    font-size: 18px;
    color: #000;
    font-weight: 700;
}

.partner-filter-data ul {
    margin: 0;
    padding: 30px 0 0;
    list-style: none;
    /* position: relative; */
}
/* 
.partner-filter-data ul:before {
    content: "";
    position: absolute;
    left: 2px;
    bottom: 24px;
    top: -10px;
    width: 1px;
    background: #D8D8D8;
} */

.partner-filter-data ul li {
    display: flex;
    flex-wrap: wrap;
    /* padding-left: 90px; */
    align-items: center;
    margin: 0 0 24px;
    position: relative;
}

/* .partner-filter-data ul li:before {
    content: "";
    position: absolute;
    left: 2px;
    top: 50%;
    margin-top: -1px;
    width: 86px;
    background: #D8D8D8;
    height: 1px;
} */
.gamecard-point {
    position: relative;
    margin-right: 50px;
    max-width: 100px;
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
    color: #000;
    font-weight: 800;
}
.gamecard-point:before {
    /* color: #000; */
    position: absolute;
    /* top: 21%; */
    /* left: 12px; */
    /* font-weight: 700; */
    content: '';
    left: 2px;
    top: 100%;
    margin-top: -1px;
    width: 107px;
    background: #D8D8D8;
    height: 1px;
}


.partner-filter-data .count {
  font-size: 16px;
  font-weight: 700;
  background-image: linear-gradient(90deg, #FDD100, #FDD100 55%, transparent 55%, transparent 100%);
  background-size: 10px 5px;
  background: #FDD100;
  color: #000;
  min-width: 64px;
  text-align: center;
  padding: 4px;
  border-radius: 8px;
  margin-right: 20px;
}

.dept-name {
    padding:15px 90px 15px 20px;
    background: #F3F4F5;
    flex: 1;
    border-radius: 12px;
}

.dept-name h5 {
    margin: 0;
    font-size: 16px;
    color: #000;
    font-weight: 300;
}



.template-content {
    /* min-height: calc(100vh - 180px); */
    border-left: 1px solid #D8D8D8;
    padding-left: 30px;
    padding-top: 30px;
    background: #F3F4F5;
    position: relative;
    padding-right: 20px;
    margin-right: -30px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 600px;
}

.partner-left {
    min-height: calc(100vh - 180px);
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100% - 290px);
   
}

.template-content:before {
    content: "";
    position: absolute;
    left: 0px;
    background: #F3F4F5;
    right: -30px;
    top: 0;
    bottom: 0;
}

.template-search {
    max-width: 100%;
}

.template-search input.form-input {
    background: #FFF;
}

.template-search-result {
    position: relative;
    /* max-height: calc(100vh - 290px);
    overflow: auto; */
}

.template-search-result ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.template-search-result ul li {
    background: #FFF;
    margin-top: 20px;
    border-radius: 12px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.result-single h3 {
    margin: 0 0 5px;
    color: #000;
    font-size: 24px;
}

.result-single p {
    margin: 0;
}

.result-action .button {
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    min-width: 100px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 20px;
    
}

.page-wrapper.partner-page {
    padding-bottom: 0;
}

.icon-eye{
    position: absolute;
    top: 12px;
    right: 20px;
    cursor: pointer;
}
.icon-eye1{
    position: absolute;
    top: 18px;
    right: 20px
    
}
/**** Sign-In Page Style ****/
.text-center {
    text-align: center;
}

div.sign-in {
    height: 100vh;
    width: 100%;
    display: block;
    background: #979797;
}
.sign-in.blur {
    filter: blur(5px);
  }

.sign-in-main-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; 
    padding: 0 20px;
}

.center-main-block span.close {
    position: absolute;
    height: 20px;
    width: 20px;
    right: 20px;
    top: 20px;
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath id='Path' d='M2.2.133.133,2.2a.455.455,0,0,0,0,.643L7.291,10,.133,17.157a.455.455,0,0,0,0,.643L2.2,19.867a.455.455,0,0,0,.643,0L10,12.709l7.158,7.158a.454.454,0,0,0,.643,0L19.867,17.8a.454.454,0,0,0,0-.643L12.709,10l7.158-7.155a.455.455,0,0,0,0-.643L17.8.133a.455.455,0,0,0-.643,0L10,7.291,2.844.133a.454.454,0,0,0-.643,0Z' transform='translate(0 0)' fill='%23ff5c5c'/%3E%3C/svg%3E%0A");
    background-size: 20px;
    cursor: pointer;
}

.center-main-block span.close:hover {
    opacity: 0.9;
}

.center-main-block {
    width: 700px; 
    background: #fff;
    /* padding: 80px 30px 50px; */
    position: relative;
}

.center-main-block h3 {
    color: #000000;
}

.auth-wrapper .center-block-header h3{
    margin-bottom: 20px;
}

.auth-wrapper .form-block{
    margin-top: 40px;
    width: 386px;
}
.auth-wrapper .form-block .label{
    font-size: 18px !important;
    line-height: 24px;
    font-weight: 600;
    margin-top: 0;
}

.auth-wrapper .form-block .control-input{
    padding: 18px 16px;
    font-size: 18px;
    line-height: 22px;
}
.react-datepicker__input-container .control-input{
    font-size: 16px !important;
    width: 270px !important;
}
.center-main-block .header-logo {
    margin-bottom: 16px;
}

.center-block-header.final-screen {
    margin-bottom: 0;
}

.center-block-header.final-screen .header-logo {
    margin-top: 32px;
    margin-bottom: 0;
}

.center-block-header {
    /* margin-bottom: 24px; */
}

.form-block .label {
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    font-weight: 700;
    margin-bottom: 12px;
    margin-top: 12px;
}
.font15 {
    font-size: 15px !important;
}

.form-block .control-input {
    background: #F3F4F5;
    border: 0.25px solid #CACACA;
    outline: none;
    width: 100%;
    padding: 15px;
    border-radius: 15px;
    color: #000;
    font-size: 16px;
    line-height: 22px;
}
.form-block .user-control-input{
    color: #000 !important;
}
.form-block .user-control-input::placeholder{
    color: #959595 !important;
    
}
.control-input::placeholder {
    color:#959595;
    opacity: 1; 
  }
.form-block .new-password-field {
  min-width: 386px;
}

.padd10Radius10{
    padding: 10px !important;
    border-radius: 10px !important;
}

.form-block .control-input:focus {
    border-color: #FDD100;
}

.form-block .control > a:hover {
    color: #6191C7;
}

.form-block .form-field:not(:first-child) {
    margin-top: 24px;
}
.marginTop10{
    margin-top: 10px !important;

}
.auth-wrapper .form-block .form-field:not(:first-child) {
    margin-top: 25px;
}
.form-block .form-field.otp-button {
    margin-top: 15px;
}

.form-block .form-field.otp-button .submit {
   background: #F7F7F7;
   border-color: transparent;
   cursor: not-allowed;
   padding: 18px;
   font-size: 16px;
   line-height: 20px;
   border-radius: 8px;
}

.form-block .form-field.otp-button .submit:enabled {
    background: #FDD100;
    border-color: #FDD100;
    cursor: pointer;
 }

.form-block .form-field .submit {
    cursor: pointer;
    min-width: 330px;
    text-align: center;
    transition: all ease-in 0.25s;
    font-weight: 500;
    min-height : 56px;
    font-size : 16px;
}

.form-block .form-field.two-column {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
} 

.form-block .control-checkbox {
    position: absolute;
    opacity: 0;
    z-index: 1;
}

.form-block .control-checkbox + .label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    font-weight: 600;
    margin: 0;
}

.form-block .control-checkbox + .label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 18px;
    height: 18px;
    border: 1px solid#979797;
    border-radius: 5px;
    background: #FDD100;
}

.form-block .control-checkbox:focus + .label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.form-block .control-checkbox:checked + .label:before {
    background: #FDD100;
    border-color: #FDD100;
}

.form-block .control-checkbox:disabled + .label {
    color: #b8b8b8;
    cursor: auto;
}

.form-block .control-checkbox:disabled + .label:before {
    box-shadow: none;
    background: #ddd;
}

.form-block .control-checkbox:checked + .label:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 8px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
}

 .center-block-header h3{
    font-size: 30px;
    line-height: 30px;
    color: #000000;
}

.center-block-header span{
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 0;
}

.otp-wrapper .center-block-header h3{
    font-size: 30px;
    line-height: 30px;
    color: #000000;
    margin: 40px 0 30px;
}

.otp-wrapper .center-block-header span{
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 0;
}

#otp-field{
    border-color: #FFCC00;
}

.otp-block input:focus,
.otp-block input:focus-visible{
    border-color: #d2d2d2;
    outline: none;
}


.otp-block input[type=number] {
    -moz-appearance: textfield !important;
}


.otp-block input{
    border: 2px solid #d2d2d2;
    border-radius: 4px;
}
.otp-block.filled input{
    border-color: #FDD100;
}


/**** Sign-In Page Style ****/


.custom-select {
    position: relative;
    margin-right: 18px;
}

.custom-select svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.custom-select select {
    width: 220px;
    margin: 0;
}

.template-result-block .partner-heading svg {
    position: absolute;
    right: 0;
    pointer-events: none;
}

.template-result-block .template-search-result .no-data{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.partner-filter-block .partner-heading:after {
    content: '';
    height: 100%;
    width: 1px;
    position: absolute;
    background: #e3e5e7;
    right: -1px;
    top: 0;
    bottom: 0;
    z-index: 9;
}

.partner-filter-block .partner-left .no-data{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.partner-filter-block .partner-left h2 {
    color: #000;
    font-size: 20px;
    margin-bottom: 12px;
}

.partner-filter-block .partner-left h4 {
    color: #000;
    max-width: 200px;
    font-size: 16px;
    font-weight: 400;
}

.partner-filter-block .partner-left img {
    display: inline-block;
    margin-bottom: 18px;
} 
.header-wrapper .form-input{
    padding: 16px 20px;
    font-size: 14px;
    line-height: 24px;
    border: none;
}
.employeeContainer-first{
    width: 70%;
}
.employeeContainer-second{
    width: 30%;
}
.emp-peronal{
    width: 40%;
    padding: 24px;
    border: 1px solid #D8D8D8;
    background-color: #F5F5F5;
    border-radius: 10px;
    height: fit-content;
}
.emp-work{
    width: 40%;
    padding: 24px;
    border: 1px solid #D8D8D8;
    background-color: #F5F5F5;
    border-radius: 10px;
    height: fit-content;
}
/*------ RESPONSIVE ------*/
@media (max-width: 1469px) {
    .employeeContainer-first{
        width: 60%;
    }
    .employeeContainer-second{
        width: 40%;
    }
    .emp-peronal{
        width: 45%;
    }
    .emp-work{
        width: 45%;
    }
    .header-wrapper .form-input{
        padding: 12px 16px;
        color: #8F92A1
    }

    .form-input,
    select {
        padding: 10px 20px;
    }

    h1 {
        font-size: 30px;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 20px;
    }

    h4 {
        font-size: 18px;
    }

    h5 {
        font-size: 16px;
    }

    h6 {
        font-size: 14px;
    }

    .button {
        font-size: 14px;
        line-height: 20px;
    }

    table.table th {
        font-size: 16px;
        padding: 13px 20px;
    }

    table.table td {
        font-size: 14px;
        padding: 13px 20px;
    }

    .upload-user-data .button {
        padding: 23px 43px 23px 35px;
    }

    .page-sidebar {
        max-width: 200px;
        padding: 15px;
    }

    .navigation {
        padding-top: 15px;
    }

    .navigation ul a {
        font-size: 14px;
        padding: 5px 10px;
    }

    .page-wrapper {
        padding-left: 200px;
        /* padding-right: 20px; */
    }

    .search-box button {
        top: 14px;
        right: 20px;
    }

    .search-box button img {
        width: 19px;
    }

    .upload-user-data {
        /* padding-top: 10px; */
    }

    .welcome-user>h2 {
        font-size: 24px;
    }

    .welcome-user .inner-col {
        height: 130px;
    }

    .table-header a {
        font-size: 16px;
    }

    .custom-select select {
        width: 160px;
    }

    .partner-filter-form .control,
    .partner-filter-form .form-group {
        flex-wrap: nowrap;
    }

    .partner-heading label {
        font-size: 14px;
        line-height: 18px;
        margin-right: 10px;
    }

    .partner-filter-form .button {
        min-width: 120px;
    }

    .partner-filter-form select {
        width: 160px;
        padding: 8 18px;
    }

}

@media (max-width: 1199px) {
    .page-sidebar {
        max-width: 170px;
        padding: 15px 10px;
    }

    .page-wrapper {
        padding-left: 170px;
    }

}

@media (max-width: 1023px) {
    .partner-block > .row {
        flex-direction: column;
    }
}

@media (max-width: 991px) {
    .page-sidebar {
        max-width: 80px;
        padding: 15px 10px;
    }

    .mobile-logo {
        display: inline-block;
    }

    .desktop-logo {
        display: none;
    }

    .site-logo {
        text-align: center;
    }

    .page-wrapper {
        padding-left: 100px;
    }

    .header-right .account-link li {
        margin-left: 20px;
    }

    .welcome-user {
        max-width: 430px;
    }

    .welcome-user .col {
        margin-right: 20px;
    }

    .user-info h4 {
        font-size: 20px;
    }

    .user-info label {
        font-size: 14px;
    }

    .user-action .bake {
        top: 0;
    }

    .edit-form-wrapper .block-title h5 {
        font-size: 16px;
    }

    label {
        font-size: 14px;
        margin: 0 0 7px;
    }

    .graph-row {
        grid-gap: 20px;
    }
}

@media (max-width: 767px) {
    
    .graph-row {
        grid-template-columns: repeat(1, 1fr);
    }

    .flex-row .col,
    .flex-row .col.col-2,
    .flex-row .col.col-29,
    .flex-row .col.col-3,
    .flex-row .col.col-5{
        flex: 100%;
        width: 100%;
    }

    .dashboard-info {
        padding-top: 15px;
    }

    .welcome-user {
        max-width: 100%;
    }

    .welcome-user>h2 {
        font-size: 20px;
        padding: 0;
    }

    .upload-user-data {
        max-width: 100%;
        flex: 0 0 100%;
        text-align: center;
        /* padding-top: 15px; */
    }

    table.table th,
    table.table td {
        font-size: 16px;
        padding: 10px 15px;
        white-space: nowrap;
    }

    table.table td {
        font-size: 14px;
    }

    
    /**** Sign-In Page Style ****/
    
    .center-main-block {
        width: 100%;
        /* padding: 40px 20px 32px; */
    }

    .center-main-block span.close {
        height: 14px;
        width: 14px;
        right: 14px;
        top: 14px;
        background-size: 14px;
    }

    .form-block .control-input {
        font-size: 14px;
        line-height: 20px; 
        padding: 10px 12px;
        border-radius: 12px;
    }


    .form-block .label { 
        font-size: 15px;
        line-height: 20px; 
        font-weight: 700;
        margin-bottom: 8px;
        margin-top: 8px;
    }

    .form-block .form-field.otp-button {
        margin-top: 24px;
    }

    .form-block .form-field .submit {
        min-width: 170px;
        padding: 7px 10px;
    }

    .form-block .form-field:not(:first-child) {
        margin-top: 16px;
    }
    

    /**** Sign-In Page Style ****/

    .partner-heading {
        height: auto;
    }

    .partner-filter-form .form-group {
        flex-direction: column;
        row-gap: 15px;
    }

    .partner-filter-form {
        padding-right: 0;
    }

    .partner-filter-form .control {
        flex-wrap: wrap;
        row-gap: 12px;
        justify-content: space-between;
    }

    .partner-filter-form .custom-select , 
    .partner-filter-form select {
        width: 100%;
        margin: 0;
    }

    .partner-filter-block .partner-heading::after {
        display: none;
    }

}

@media (max-width: 574px) {
    .header-wrapper .row {
        flex-direction: column-reverse;
    }

    .header-right .account-link {
        justify-content: flex-end;
        padding: 0 0 10px;
    }

    .welcome-user {
        padding-top: 20px;
    }

    .welcome-user .col {
        margin-right: 0;
        flex: 0 0 100%;
        padding: 0 0 20px;
    }

    .table-header {
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 15px 0;
    }

    .table-header h4 {
        font-size: 16px;
    }

    .table-header a {
        font-size: 14px;
    }

    .dashboard-table .responsive-table {
        padding: 0 15px;
    }

    table.table th,
    table.table td {
        font-size: 14px;
        padding: 8px 10px;
    }
}

.model-block {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: none;
}
.component-block {
    display: block;
}
.model-block div.sign-in{
    background: rgba(0, 0, 0, 0.3);
}
.landing-top.filter-blur{
    filter: blur(2px);
  -webkit-filter: blur(2px);
}
.attr-name {
    max-width: 170px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}

/*** 09-06-2023 --- Style ***/




.dashboard-boxes.box-with-content  > div {
    padding: 0 12px;
}

.dashboard-boxes.box-with-content {
    margin: 0 -12px;
}

.dashboard-boxes.box-with-content + .dashboard-boxes {
    padding-top: 24px;
}

.top-graph {
    position: absolute; 
    right: 0;
    bottom: -5px;
    left: 0px;
}

.top-graph img {
    width: 100%;
}

.dashboard-boxes.box-with-content .dashboard-box-inner h5 {
    font-size: 24px;
    font-weight: 500;
    margin: 0; 
}

.dashboard-boxes.box-with-content .dashboard-box-inner h5.blue {
    color: #486484;
}

.dashboard-boxes.box-with-content .dashboard-box-inner h5.green {
    color: #8CBD3F;
}

.dashboard-boxes.box-with-content .dashboard-box-inner {
    min-height: 160px; 
    align-items: flex-start;
    position: relative;
}

.dashboard-boxes.box-with-content .dashboard-box-inner.flexi-height {
    min-height: inherit;
}

 

.dashboard-boxes {
    padding-top: 45px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
}
.dashboard-box a {
    width: 100%;
}
.dashboard-boxes > div { 
    width: 16.66%;
    padding: 0 20px;
    text-align: center;
}

.dashboard-boxes .dashboard-box-inner {
   
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    height: 100%;
}

.dashboard-boxes .dashboard-box-inner .dashboard-icon {
    box-shadow: 0px 0px 20px -4px rgba(163, 153, 163, 1);
    border-radius: 50%;
    height: 75px;
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}

.dashboard-boxes .dashboard-box-inner h5,
.dashboard-boxes.box-with-content .dashboard-box-inner h6 {
    font-weight: bold;
    font-size: 16px;
    margin: 0;
}

.dashboard-boxes.box-with-content .dashboard-box-inner h6 {
    margin-bottom: 10px;
    color: #000000;
}


.content-wrapper.top-spacing {
    padding-top: 24px;
}

.content-header {
    padding: 19px 32px;
    background: #F3F4F5;
    margin: 0 -32px;
    border-bottom: 1px solid #e3e5e7;
    display: flex;
align-items: center;
   
}

.content-header h3 {
    color: #000;
    margin: 0;
    font-size: 18px; 
    display: flex;
    align-items: center;
}

.content-header h3:before {
    content: '';
    background: no-repeat center;
    background-size: 24px;
    height: 24px;
    width: 24px;
    display: inline-block;
    margin-right: 10px;
}


.graph-block {
    margin-top: 32px;
    width: 1300.08px;
    height: 500px;
}


.graph-block h3 {
    font-weight: 500;
    color: #486484;
}

.graph-selection {
    display: flex;
    justify-content: flex-end;
}

.graph-selection select {
    color: #486484;
    border: 1px solid #486484;
    padding: 4px 40px 4px 15px;
    border-radius: 30px;
    outline: none;
    margin-left: auto;
    background: #fff;
    font-size: 12px;
}

.graph-select-inner {
    max-width: 140px;
    position: relative;
}

.graph-select-inner:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #486484 transparent transparent transparent;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
} 

@media screen and (max-width: 1600px) {

    .dashboard-boxes {
        padding-top: 45px; 
        margin: 0 -12px;
    }
    
    .dashboard-boxes > div { 
        width: 240px;
        padding: 0 12px; 
    }

}

@media screen and (max-width: 1200px) {
    .dashboard-boxes > div { 
        width: 50%; 
        margin-bottom: 24px;
    }
}


@media screen and (max-width: 767px) {

    .dashboard-boxes > div { 
        width: 100%; 
        margin-bottom: 0;
    }

    .dashboard-boxes > div, .dashboard-boxes.box-with-content > div {
        width: 100%;
    }

    .dashboard-boxes > div + div  {
        margin-top: 24px;
    }

    .graph-selection {
        justify-content: flex-start;
    }

    .dashboard-boxes.box-with-content .dashboard-box-inner {
        max-width: 240px;
    }



}

.step-form input[type="file"]::-webkit-file-upload-button, .step-form input[type="file"]::file-selector-button {
    display: none;
}


@media screen and (max-width: 1199px) {

    .navigation .MuiListItemIcon-root {
        min-width: inherit;
        margin-right: 10px;
    }

}

@media screen and (max-width: 991px) {
    .menu-item-text {
        display: none;
    }

    .page-sidebar {
        max-width: 52px;
        padding: 10px 6px;
    }

    .page-wrapper {
        padding-left: 52px;
    }

    .navigation ul a {
        padding: 5px;
        text-align: center;
    }

    .navigation .MuiListItemIcon-root {
        min-width: inherit;
        max-width: 24px;
        margin-right: 0;
    }

}

@media screen and (max-width: 767px) {
    .dashboard-chart div#apexchartsalueppae {
        height: 250px !important;
        width: 100% !important;
    }
}



.input-group input[type="date"]::-webkit-calendar-picker-indicator {
    display: block;
}

.input-group input[type="date"]::-webkit-inner-spin-button,
.input-group input[type="date"]::-webkit-calendar-picker-indicator {
    display: block;
    -webkit-appearance: inherit;
}

.labeldisabled {
    color: #999;
  }

  .disableformicon {
    opacity: 0.5; 
  }
  .disableformiconEMP{
    height: 65%;
    width: 15%;
  }
.content-wrapper{
    position: relative;
}
@media (max-width: 762px) {
    .content-wrapper {
        position: relative !important;
      
    }
}

.content-wrapper .form-group{
    display: flex;
    flex-wrap: wrap;
    position: relative;
}
  
.edit-form-wrapper.page-loading{
    height: 100vh;
}

/*** 27-07-2023 Style ***/

.calender-main {
    display: flex;
    padding: 40px 0;
}

.right-sidebar-top {
    text-align: right;
    margin-bottom: 30px;
}

.right-sidebar-top .button {
    background: #6191C7;
    border: 1px solid #6191C7;
    color: #fff;
    padding: 10px 18px;
    margin-right: 25px;
}

.calender-main .custom-right-sidebar {
    width: 290px;
}

.calender-main .custom-calender {
    width: calc(100% - 290px);
}

.event-blocks h3 {
    color: #000;
    font-size: 18px;
}

.event-blocks {
  height: 71vh;
  overflow: auto;
}

@media screen and (max-width: 1023px) { 
    .event-blocks {
       height: unset;
       overflow: unset;
      }
}


.event-block h5 {
    color: #000;
    font-size: 16px;
}

.event-block ul h5 {
    font-size: 14px;
    margin-bottom: 6px;
}

.event-block {
    box-shadow: 0px 4px 15px 0 #ddd;
    padding: 20px;
    margin: 4px;
    list-style: none;
    border-radius: 15px;
}

.event-block ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
 .event-title-name{
    font-size: 14px !important;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: #0A1629;
    word-wrap:anywhere;
    }

.event-block ul li {
    background: #F4F9FD;
    border-radius: 14px;
    padding: 12px 20px;
    position: relative;
}

.event-block ul li.unsuccess:before {
    background: #FF3A29;
}

.event-block ul li:before {
    content: '';
    top: 10px;
    bottom: 10px;
    width: 4px;
    background: #34B53A;
    position: absolute;
    border-radius: 4px;
    left: 7px;
}

.event-block ul li + li {
    margin-top: 10px;
}

.event-block + .event-block {
    margin-top: 24px;
}

@media screen and (max-width: 1023px) { 

    .calender-main {
        flex-direction: column;
    }

    .calender-main .custom-right-sidebar,
    .calender-main .custom-calender {
        width: 100%;
    }

}

@media screen and (max-width: 767px) {
    
    .event-block {
        padding: 18px 14px;
    }

    .right-sidebar-top .button {
        padding: 8px 16px;
    }
}


.calendar-todo-list {
    text-align: left;
    padding: 0;
    list-style: none;
  }
  
  .calendar-todo-list li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .calendar-todo-item-badge {
    vertical-align: top;
    margin-top: 8px;
    width: 6px;
    height: 6px;
  }
  .rs-calendar-panel
    .rs-calendar-table-row:not(.rs-calendar-table-header-row)
    .rs-calendar-table-cell-selected
    .rs-calendar-table-cell-content {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background-color: rgb(128, 187, 128);
    box-shadow: none;
  }
  .rs-calendar-panel
    .rs-calendar-table-row:not(.rs-calendar-table-header-row)
    .rs-calendar-table-cell-selected
    .rs-calendar-table-cell-content
    span {
    background-color: rgb(128, 187, 128);
  }
  .rs-calendar-table-cell-content {
    align-items: center;
    justify-content: center;
    display: inline-flex;
  }
  .rs-calendar-panel
    .rs-calendar-table-cell.rs-calendar-table-cell-is-today
    .rs-calendar-table-cell-content
    .rs-calendar-table-cell-day {
    background-color: #fad500;
  }
 /* Calendar.css */

/* Style for the container of the calendar */
.calendar-container {
    max-width: 500px;
    margin: 0 auto;
  }
  
  
  
  
  .bullet-container {
    position: relative;
  }
  
  .bullet {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  /* Style for the red bullet marker */
  .bullet[style*="background-color: red"] {
    background-color: red;
    /* top: -11px;
    position: relative; */
    bottom: 47px;
    position: relative;
    left: 10px;
  }
  
  /* Style for the green bullet marker */
  .bullet[style*="background-color: green"] {
    background-color: green;
    /* top: -11px;
    position: relative; */
    bottom: 47px;
    position: relative;
    left: 10px;
  }
  
  /* Style for weekend dates (Saturday and Sunday) */
  .black-date {
    color: black;
    font-weight: bold;
  }
  
  .react-calendar__month-view__days__day--weekend {
    color: "";
    /* font-weight: bold */
  }
  
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    border-radius: 50%;
  }
  
  /* ... other styles ... */


  .custom-calender .bullet{
    top: 32px;
    position: absolute;
    right: 30%;
    left: inherit;
  }

  .custom-calender .react-calendar__month-view__weekdays  abbr {
    text-decoration: none;
  }

  .custom-calender .calendar-container, .custom-calender .react-calendar {
    max-width: 100%;
  }

  .custom-calender .react-calendar {
    width: 90%;
    margin: 0 auto;
    border: 0;
   
  }

  .custom-calender .react-calendar__month-view__days__day--weekend {
    color: #000
  }

  .custom-calender .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }

  .custom-calender .react-calendar__month-view__weekdays,
  .custom-calender .react-calendar {
    font-size: 18px;
  }

  .custom-calender .react-calendar button {
    padding: 16px 16px;
    font-size: 20px;
    background: transparent !important;
    position: relative;
  }

  .custom-calender .react-calendar button abbr {
    padding: 10px;
    border-radius: 50%;
    width: 60px;
    display: flex;
    text-align: center;
    height: 60px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    color: #000;
  }

  .custom-calender .react-calendar button:hover abbr{
    color: #000 !important;
    background-color: #ddd;
  }

  .custom-calender .react-calendar button abbr .bullet-container {
    position: absolute;
    top: 15px;
    right: 3px;
  }

 

  .custom-calender .react-calendar__navigation {
    margin-bottom: 52px;
    justify-content: center;
    height: auto;
    display: flex;
  }

  .custom-calender .react-calendar button.react-calendar__tile--now abbr {
    background: #FDD100;
    color:white
  }
  
  .custom-calender .react-calendar__navigation   .react-calendar__navigation__label {
    flex-grow: unset !important;
  }

  .custom-calender .react-calendar__navigation__label {
    font-weight: bold;
  }

  .custom-calender .react-calendar__month-view__weekdays {
    border-bottom: 1px solid #ccc;
    padding-bottom: 25px;
   
  }

  /* Add event popup  */


.header-wrapper, .content-wrapper {
    padding-right: 0px;
    padding-left: 0px;
}

 

.popup-overlay {
    position: absolute;
    top: -3px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

.right-popup {
    position: fixed;
    width: 43%; 
    right: -100%;
    background: #fff;
    top: 88px;
    /* top:10%; */
    bottom: 0;
    padding: 30px 0;
    border: 1px solid #979797;
    transition: right ease-in 0.5s;
    overflow-y: auto;
    padding-bottom: 0;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}

.right-popup::-webkit-scrollbar {
    display: none;
}

@media (max-width: 1469px) {
    .right-popup {
        top: 80px;
    }
}

 
.right-popup-open .right-popup  {
    right: 0;
}

.right-popup .popup-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 0 60px;
    position: relative;
}

.right-popup .event-scoller {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    padding: 0 60px;
}

.right-popup .form-field {
    padding: 0 60px;
}


.right-popup .form-field.select-field .control {
    position: relative;
    max-width: 50%;
}

.right-popup .form-field.select-field .select-arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -8px;
    pointer-events: none
}

.right-popup .form-field.half {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    align-items: center;
}

.right-popup .form-field .date-picker-app-wrapper {
    display: flex;
}

.right-popup .form-field .date-picker-app-wrapper .placeholder {
    background: #F3F4F5;
    border: 1px solid #979797;
    outline: none;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    padding: 15px;
    border-radius: 15px;
}



.right-popup .form-field.checkbox-field label {
    font-weight: 400;
}

.right-popup .form-block .form-field.otp-button {
    position: sticky;
    background: #fff;
    border-top: 1px solid #979797;
    bottom: 0;
    padding: 20px 60px;
    left: 0;
    right: 0;
    display: flex;
    column-gap: 12px;
}

.right-popup .form-block .form-field.otp-button .button {
    width: auto;
    padding: 10px 26px;
    min-width: 110px;
    cursor: pointer;
    font-weight: 400;
}

.right-popup .form-block .form-field.otp-button .button + .button {
    border-color: #979797;
    background: transparent;
    color: #000000;
    margin-left: 8px;
}

.right-popup .form-block .form-field.otp-button .button + .button:hover {
    border-color: #6191C7;
    background: #6191C7; 
    color: #fff;
}

.form-block textarea.control-input {
    resize: none;
    font-family: 'Open Sans', sans-serif;
}

.right-popup .event-scoller .event-btn {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-size: 6px;
    background-position: center;
    background-repeat: no-repeat; 
    cursor: pointer;
}

.right-popup .event-scoller .event-btn.prev-event { 
    background-image: url("data:image/svg+xml,%3Csvg id='Arrow_Copy_4' data-name='Arrow Copy 4' xmlns='http://www.w3.org/2000/svg' width='5.714' height='10' viewBox='0 0 5.714 10'%3E%3Cpath id='Arrow' d='M4.541,9.8.139,5.339a.484.484,0,0,1,0-.679L4.541.2a.682.682,0,0,1,.972,0,.7.7,0,0,1,0,.983L1.747,5,5.513,8.813a.7.7,0,0,1,0,.984.682.682,0,0,1-.972,0' fill='%23202020'/%3E%3C/svg%3E%0A");
}

.right-popup .event-scoller .event-btn.next-event { 
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.714' height='10' viewBox='0 0 5.714 10'%3E%3Cg id='Arrow_Copy_4' data-name='Arrow Copy 4' transform='translate(5.714)'%3E%3Cpath id='Arrow' d='M-4.541,9.8l4.4-4.457a.484.484,0,0,0,0-.679L-4.541.2a.682.682,0,0,0-.972,0,.7.7,0,0,0,0,.983L-1.747,5-5.513,8.813a.7.7,0,0,0,0,.984.682.682,0,0,0,.972,0' fill='%23202020'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.right-popup .popup-header h5, 
.right-popup .event-scoller h6 {
    margin: 0;
    font-size: 18px;
    color: #000;
}

.right-popup .event-scoller h6 {
    font-weight: 400;
    margin: 0 10px;
    font-size: 16px;
}

.right-popup .popup-header .close {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath id='Path' d='M2.2.133.133,2.2a.455.455,0,0,0,0,.643L7.291,10,.133,17.157a.455.455,0,0,0,0,.643L2.2,19.867a.455.455,0,0,0,.643,0L10,12.709l7.158,7.158a.454.454,0,0,0,.643,0L19.867,17.8a.454.454,0,0,0,0-.643L12.709,10l7.158-7.155a.455.455,0,0,0,0-.643L17.8.133a.455.455,0,0,0-.643,0L10,7.291,2.844.133a.454.454,0,0,0-.643,0Z' transform='translate(0 0)' fill='%23ff5c5c'/%3E%3C/svg%3E%0A");
    background-size: 20px;
    height: 20px;
    width: 20px;
    display: inline-block;
    cursor: pointer;
    position:absolute;
    right: 30px;

}

@media screen and (max-width: 1199px) {
    
    .right-popup .form-field,
    .right-popup .event-scoller,
    .right-popup .popup-header {
        padding: 0 30px;
    }

    .right-popup .form-block .form-field.otp-button {
        padding: 20px 30px;
    }

}


@media screen and (max-width: 1023px) {
    .right-popup {
        width: 50%;
    }
}

@media screen and (max-width: 767px) {
    
    .calender-main {
        flex-direction: column;
    }

    .calender-main .custom-right-sidebar,
    .calender-main .custom-calender {
        width: 100%;
    }

    .calender-main .custom-right-sidebar {
        order: 1;
    }

    .calender-main .custom-calender {
        order: 2;
    }

    .event-block {
        padding: 18px 14px;
    }

    .right-sidebar-top .button {
        padding: 8px 16px;
    }

    .right-popup {
        width: 100%;
        top: 0;
        z-index: 99;
    }
}

/* range -picker css */


.placeholder {
    border: 1px solid gray;
    padding: 7px 10px;
    border-radius: 3px;
    color: Black;
    min-width: 200px;
    text-align: left;
    font-size: 0.9em;
  }
  
.placeholderWrap {
    display: flex;
  } 
  
 .placeholderWrap .placeholder:last-child {
    margin-left: 10px;
  }  
  
  
  
  .date-grid-container .date-grid .day-container.selected .day {
    background-color: #fdd100 !important;
  }
  .date-grid-container .date-grid .day-container.hovered {
    background-color: #fff7c6 !important;
  }
  .default-footer {
    display: none !important;
  }

  .date-grid-container .date-grid .day-container.today .day {
    border:none !important;
    background-color: #FDD100 !important;
}

#__range-picker-container .calendar.visible,#__range-picker-container .calendar{
    top: 45% !important;
    left: 54% !important;
}
.date-grid-container .date-grid .day-container.selected.first, .date-grid-container .date-grid .day-container.selected.second {
    background-color: #fff7c6 !important;
}
.date-grid-container .date-grid .day-container.next.hovered.sat {
    border-radius: 0px 15px 15px 0px;
}
.date-grid-container .date-grid .day-container.hovered.sun {
    border-radius: 15px 0px 0px 15px;
    color: red;
}

.form-field.checkbox-field .control p {
    margin: 0px 40px;
}
.PrivateSwitchBase-root-1{
    padding: 0 !important;
}
.checkbox {
    margin-bottom: 20px !important;
}
.checkbox:nth-child(2) {
    margin: 0 !important;
}
.right-popup .form-field .checkbox input{
    margin-right: 10px;
}
.checkbox label{
    margin: 0 !important;
}
.MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-5.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-checked-6.Mui-checked.MuiIconButton-colorPrimary {
    width: 24px !important;
    height: 24px !important;
}
.MuiCheckbox-colorPrimary.Mui-checked{
    padding: 0px 9px !important;
    color: #6191C7 !important;
}
.PrivateSwitchBase-root-8 {
    padding: 0px 9px !important;
}
.right-popup .form-field .checkbox{
    align-items: center !important;
    display: flex;
    column-gap: 5px;
    margin-top: 26px;
}
.right-popup .form-field .checkbox span {
    padding: 0px !important;
}

/* department */
.MuiAccordion-rounded:last-child, .MuiAccordion-rounded:first-child{
    border: none !important;
}
.MuiPaper-elevation1{
    box-shadow: none !important;
}
.accordion-border{
    border-radius: 12px !important;
    background: #FFF !important;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.09) !important;
}
.MuiAccordion-root::before {
    height: 0 !important;
}
.accordion-border[aria-expanded="true"]{
    border-bottom: 2px solid #ddd !important;
}
.profile-img {
    margin: 0px 6px;
    display: flex;
    align-items: center;
}
.department-header h2{
    margin: 0px;
}
.department-header{
    position: relative;
    color: #000;
    padding: 15px;
    margin: 20px 0px;
}
.department-header::before {
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
    bottom: 0;
    right: -50px;
    background: #F3F4F5;
    z-index: -1;
}
.css-view-1dbjc4n label{
    display: flex !important;
    align-items: center !important;
}
.css-view-1dbjc4n label svg{
    margin-right: 10px !important;
}

.date-field-custom .control {
    position: relative;
    max-width: 270px;
}


@media screen and (max-width: 1365px) {

    .custom-calender .bullet {
        right: 10%;
    }

}


@media screen and (max-width: 1150px) {

    .custom-calender .react-calendar button {
        padding: 12px 10px;
        font-size: 16px; 
    }

    .custom-calender .react-calendar button abbr {
        padding: 6px;
        width: 40px;
        height: 40px;
    }

    .custom-calender .react-calendar__navigation {
        margin-bottom: 30px; 
    }

    .custom-calender .react-calendar__month-view__weekdays, 
    .custom-calender .react-calendar {
        font-size: 16px;
    }

    .custom-calender .react-calendar__month-view__weekdays {
        padding-bottom: 12px;
    }

    .custom-calender .bullet {
        top: 18px;
    }

}

@media screen and (max-width: 1023px) {

    .right-popup .form-field.half > div {
        width: 100%;
    }

    .right-popup .form-field .date-picker-app-wrapper .placeholder {
        padding: 10px;
    }

}

@media screen and (max-width: 767px) {
    
    .custom-calender .react-calendar {
        max-width: 100%;
        width: 100%;
        margin-top: 24px;
    }

    .custom-calender .react-calendar__month-view__weekdays, 
    .custom-calender .react-calendar, .custom-calender .react-calendar button {
        font-size: 14px;
    }

    .custom-calender .react-calendar button abbr {
        height: 28px;
        width: 28px;
    }

    .custom-calender .bullet {
        top: 14px;
    }

    .header-wrapper, .content-wrapper {
        padding-right: 0;
    }

    .field.date-field-custom {
        margin-bottom: 20px;
        position: relative;
    }

}

@media screen and (max-width: 479px) {

    .custom-calender .bullet { 
        right: -4px;
    }

}

.notification-popup-open .notification-popup {
    right: 0;
}
.notification-popup-open .popup-overlay.header-light {
    top: 75px;
}
.notification-popup-open .popup-overlay {
    position: fixed;
    top: -3px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
    display: block;
}



.notification-popup .popup-overlay {
    z-index: 99;
}

.notification-popup .popup-header .close {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath id='Path' d='M2.2.133.133,2.2a.455.455,0,0,0,0,.643L7.291,10,.133,17.157a.455.455,0,0,0,0,.643L2.2,19.867a.455.455,0,0,0,.643,0L10,12.709l7.158,7.158a.454.454,0,0,0,.643,0L19.867,17.8a.454.454,0,0,0,0-.643L12.709,10l7.158-7.155a.455.455,0,0,0,0-.643L17.8.133a.455.455,0,0,0-.643,0L10,7.291,2.844.133a.454.454,0,0,0-.643,0Z' transform='translate(0 0)' fill='%23ff5c5c'/%3E%3C/svg%3E%0A");
    background-size: 15px;
    height: 20px;
    width: 20px;
    display: inline-block;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center; 
}


.notification-popup .popup-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 0 30px;
}

.notification-popup .notification-items {
    padding: 0 30px;
}

.notification-popup .popup-header h5, 
.notification-popup .notification-item h5 {
    margin: 0;
    font-size: 18px;
    color: #000;
}

.notification-popup .notification-item {
    padding: 16px;
    background: #F3F3F3;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    margin-bottom: 24px;
}

.notification-popup .notification-item h5,
.notification-popup .notification-content {
    margin-bottom: 12px;
}

.notification-popup .notification-detail {
    display: flex;
    justify-content: space-between;
}

.notification-popup .notification-detail span {
    font-size: 12px;
    font-weight: 700;
    color: #A9A9A9;
}

.notification-popup .notification-detail a {
    color: #327EE9;
    text-decoration: underline;
    text-transform: capitalize;
} 

.notification-item p {
    color: #000;
}

.notification-item p:last-child {
    margin-bottom: 0;
}

.notification-popup {
    height: 565px; 
    transition: right ease-in 0.5s;
    overflow-y: auto;
}

.notification-content {
    padding-left: 28px;
    background-image: url("data:image/svg+xml,%3Csvg id='Iconly_Light-outline_Document' data-name='Iconly/Light-outline/Document' xmlns='http://www.w3.org/2000/svg' width='23.727' height='24' viewBox='0 0 23.727 24'%3E%3Crect id='Iconly_Light-outline_Document_Background_' data-name='Iconly/Light-outline/Document (Background)' width='23.727' height='24' fill='none'/%3E%3Cg id='Document' transform='translate(2.966 2)'%3E%3Cpath id='Document-2' data-name='Document' d='M12.717,20H5.08a6.81,6.81,0,0,1-1.157-.094l-.216-.041A4.387,4.387,0,0,1,.974,18.179,5.6,5.6,0,0,1,0,14.791V5.209a7.516,7.516,0,0,1,.059-.96C.406,1.549,2.236,0,5.08,0h7.637c3.15,0,5.049,1.918,5.079,5.132v9.659C17.8,18.052,15.9,20,12.717,20ZM5.08,1.457c-2.428,0-3.658,1.262-3.658,3.752v9.581c0,2.49,1.231,3.752,3.658,3.752h7.628c2.422,0,3.649-1.262,3.649-3.752V5.209c0-2.49-1.228-3.752-3.649-3.752Zm7.37,13.5H5.321a.724.724,0,0,1,0-1.448H12.45a.724.724,0,0,1,0,1.448Zm.078-4.225a.7.7,0,0,1-.078,0H5.321a.736.736,0,0,1,0-1.458H12.45a.7.7,0,0,1,.076,0,.718.718,0,0,1,.607.343.746.746,0,0,1,0,.78A.716.716,0,0,1,12.527,10.733ZM8.042,6.5H5.33a.735.735,0,0,1,0-1.457H8.042a.735.735,0,0,1,0,1.457Z' fill='%23200e32'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 24px;
    
}


.notification-popup::-webkit-scrollbar {
    width: 8px;
}
 
.notification-popup::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
.notification-popup::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}


@media screen and (max-width: 767px) {
    .notification-popup {
        z-index: 99;
        margin: 0 auto;
        left: 0;
        width: 95%;
        top: 50%;
        transform: translateY(-50%);
    }
}
.logout-box{
    position: absolute !important;
    top: 50px !important;
    z-index: 99;
}
.logout-box .MuiPaper-outlined {
    height: 40px !important;
}
@media (max-width:574px){
    .search-box{
        top: 0px;
    }
}

/* ========= Profile Screen ========= */

.admin-profile-details {
    text-align: center;
    background: #F3F3F3;
    padding: 18px 0 40px;
}

.admin-profile-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
}

.admin-profile-nav ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
}

.admin-profile-nav ul li {
    width: 50%;
    padding: 0 0 20px;
    margin-left: 0 !important;
    display: flex;
    align-items: center;
    gap: 20px;
    color: #000;
}

.admin-profile-nav ul li img {
    width: 20px;
    height: 20px;
}

.admin-profile-nav ul li p {
    margin: 0;
    font-size: 16px;
    line-height: 20px;
}

.admin-profile-nav {
    padding: 15px 35px;
}

.admin-profile-details h3 {
    color: #000;
    font-weight: 400;
    font-size: 22px;
    line-height: 20px;
    margin: 12px 0 7px;
}

.admin-profile-details small {
    color: #1CA0FF;
    font-size: 12px;
    display: block;
}

.admin-profile-heading h3 {
    color: #000;
    font-weight: 400;
}

.admin-profile-details > img {
    width: 94px;
    height: 94px;
    object-fit: cover;
    border: 2px solid #FFCC02;
    border-radius: 50%;
}

.admin-profile-heading a {
    color: #486484;
    text-decoration: underline;
    font-size: 14px;
    line-height: 20px;
}

.admin-profile-details ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 20px 0 0 0;
    gap: 70px;
}

.admin-profile-details ul li span {
    background-color: #fff;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 7px
}

.admin-profile-details ul li span img {
    width: 100%;
    height: auto;
    object-fit: cover;

}

.admin-profile-details ul li {
    margin-left: 0 !important;
}

.admin-profile-details ul li p {
    font-size: 14px;
    line-height: 14px;
    color: rgb(0 0 0 / 85%);
    margin: 0;
}

.admin-profile-button button.log-out-btn span {
    background: #FFCC00;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.admin-profile-button button.log-out-btn {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    gap: 8px;
    font-size: 16px;
    font-weight: 400;
}

.admin-profile-button .log-out-btn img {
    width: 20px;
    height: 20px;
}

.admin-profile-button {
    margin-top: 10px;
}
.admin-profile-popup .account-link li:first-child img {
    width: auto;
}
.admin-profile-nav ul li a {
    color: #0082D3;
}
.profile-popup{
    height: 715px;
}

@media (max-width:574px){
    .admin-profile-nav ul{
        display: block;
    }
}
.form-group p {
    padding: 13px;
    background-color: #F4F4F5;
    border: 1px solid #e3e5e7;
    font-size: 16px;
    border-radius: 12px;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    position: relative;
}
.form-group p span {
    right: 20px;
    position: absolute;
}

.profile-with-form{
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;
}
.profile-with-form .profile-side-wrapper {
    flex: 0 0 30%;
    text-align: center;
    border-right: 1px solid #e7dfdf;
}
.emp-profile-side-wrapper{
    display: flex;
    width: 100%;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.09);
    margin: 0px 70px;
}
.profile-with-form.profile-icon {
    text-align: center;
    display: inline-block;
    width: 70px;
    height: 70px;
}
.user-profile{
    position: relative;
}
.pencil-icon {
    position: absolute;
    bottom: 0;
    right: 0;
}
.profile-icon img[alt=user-profile] {
    width: 70px;
    height: 70px;
    border: 1px solid gold;
    border-radius: 100%;
}
.profile-details h3 {
    color: #000;
    margin-bottom: 0;
}
.emp-profile-details{
    display: flex;
    width: 60%;
    align-items: center;
    gap: 30px;
    margin-left: 100px;
}
.emp-achivments-wrapper{
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: space-between;
    margin-right: 100px;
}
.emp-achivments-icon-data{
    display: block;
}
.emp-profile-tab{
    display: flex;
    font-weight: bold;
    width: 100%;
    color: #000000;
    justify-content: space-between;
    padding: 50px 120px;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 0px;
}
.static-profile-tab{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 19px 120px;
    padding-bottom: 0px;
    background-color: rgba(243, 244, 245, 1);
}
.emp-personal-work-container{
    display: flex;
    width: 100%;
    margin: 40px 72px;
    gap: 63px;
}
.emp-tab-border{
  border-bottom: 4px solid #FFCC02;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
.static-tab-border{
    border-bottom: 4px solid #FFCC02;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
}
.emp-tab{
  padding-left: 10px;
  padding-right: 10px;  
  cursor: pointer;
}
.static-tab{
    padding-left: 10px;
    padding-right: 10px;  
    cursor: pointer;
    font-weight: 400;
    color: rgba(162, 162, 162, 1);
  }
.emp-badges{
    margin: 30px 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 33px;
    row-gap: 33px;
    justify-content: center;
}
.emp-badges-card{
    width: 48%;
    background-color: #F3F3F3;
    border: 2px solid #D8D8D8;
    border-radius: 20px;
    display: flex;
    padding: 17px 26px;
}
.emp-e-learning{
    margin: 30px 40px;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 20px;
}
@media (max-width:1440px){
.emp-e-learning{
    grid-template-columns: auto auto auto;
}
}
.emp-e-learning-card{
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.profile-details p {
    color: #1da0ff;
}
.achivments-icon-data {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 32px 15%;
}
.achivments-icon {
    display: flex;
    align-items: center;
}
.point-font{
    color: #000;
}
.point-table {
    display: flex;
    justify-content: space-between;
    font-weight: 800;
    color: #000;
    letter-spacing: 4.2px;
}
.profile-with-form .content-wrapper {
    flex: 0 0 70%;
    padding-left: 30px
}
/* .flex-row .col-6 {
    flex: 0 0 53%;
} */
.profile-with-form .step-form {
    flex: 0 0 70%;
    padding: 0 40px 40px
}
/* .block-content .col.col-3 {
    flex: 0 0 33.33%;
} */
.step-form:nth-child(3) {
    border-bottom: 1px solid #CBCBCB;
    width: 100%;
    margin-bottom: 10px;
}
.profile-icon {
    margin: 35px 0px;
}
@media (max-width:991px){
    .achivments-icon-data{
        margin: 0;
    }
}
@media (max-width:767px){
    .profile-with-form .profile-side-wrapper{
        flex: 0 0 100%;
        border-right: none;
    }
    .profile-with-form .step-form{
        flex: 0 0 100%;
        padding: 0 0 40px;
    }
   
    .col, .col.col-2, .col.col-3, .col.col-4,.col-6{
        flex: 0 0 100%;
    }
    .col,.col-2,.col-3,.col-4,.col-5,.col-6{
        flex: 0 0 100%;
    }
    .block-content .col.col-3{
        flex: 0 0 100%;
    }
    .flex-row .col-6{
        flex: 0 0 100%;
        
    }
}
.upload-user-data button.button {
    width: 100%;
}

.col-15{
    flex: 0 0 15%;
    padding: 0 15px;
    margin: 15px 0;
}
.col-2{
    flex: 0 0 20%;
    padding: 0 15px;
    margin: 15px 0;
}
.col-29{
    flex: 0 0 29%;
    padding: 0 15px;
    margin: 15px 0;
}
.col-3{
    flex: 0 0 33.33%;
    padding: 0 15px;
    margin: 15px 0;
}
.col-37{
    flex: 0 0 37.67%;
    padding: 0 15px;
    margin: 15px 0;
}
.col-51{
    flex: 0 0 51.67%;
    padding: 0 15px;
    margin: 15px 0;
}
.col-40{
    flex: 0 0 40%;
    padding: 0 15px;
    margin: 15px 0;
}

.col-66{
    flex: 0 0 66.67%;
    padding: 0 15px;
    margin: 15px 0;
}
.col-30{
    flex: 0 0 30%;  
    padding: 0 15px;
    margin: 15px 0;
}
.col-26{
    flex: 0 0 26%;  
    padding: 0 15px;
    margin: 15px 0;
}
.col-44{
    flex: 0 0 44%;  
    padding: 0 15px;
    margin: 15px 0;
}
.col-50{
    flex: 0 0 50%;  
    padding: 0 15px;
    margin: 15px 0;
}
.col-6{
    flex: 0 0 60%;  
    padding: 0 15px;
    margin: 15px 0;
}
.col-27{
    flex: 0 0 27%;  
    padding: 0 15px;
    margin: 15px 0;
}
.col-56{
    -ms-flex: 0 0 56%;
    flex: 0 0 56%;
    padding: 0 15px;
    margin: 15px 0;
}
@media (max-width:1300px){
    .col-51{
        -ms-flex: 0 0 44.67%;
        flex: 0 0 44.67%;
    }
    .col-15{
        -ms-flex: 0 0 22%;
        flex: 0 0 22%;
    }
}
.navigation ul a:hover, .navigation ul li>a.sidebar-list-item-active {
    color: #000;
    border-radius: 8px;
    background: #FDD001;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.09);
}
.custom-calender .react-calendar button abbr:focus {
    background: #fdd100;
}.flag-with-input {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    width: 100%;
}
.flag-with-input .flag {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: 14px;
    left: 5px;
}
.flag-input-field input#contact_info {
    padding-left: 80px;
}
.flag-with-input .flag .right-border{
    border-right: 1px solid #000;
    margin: 0 5px  0px;
}
.flag-input-field {
    width: 100%;
}
.react-tel-input .flag-dropdown{
    top: -11px;
}


.react-tel-input .form-control {
    font-size: 16px !important;
    line-height: 20px !important;
    padding: 15px 90px !important;
    border-radius: 12px !important;
    width: 100% !important;
    font-weight: 500 !important;
    background: #F3F4F5 !important;
}

@media(max-width: 1469px){
    .react-tel-input .form-control {
        padding: 10px 10px 10px 90px !important;
    }

}
.react-tel-input .form-control:focus {
    box-shadow: none !important;
    border:  1px solid #FDD100 !important;
}
.react-phone-input-2 .arrow {
    display: none !important;
  }
/* .myflag img {
    margin: 0 0px 0px 18px;
} */
.flag-with-input .myflag {
    display: flex;
    position: absolute;
    top: 17px;
    left: 31px;
    margin-left: 4px;
}
.react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before {
    box-shadow: none !important;
    border: none !important;
}
.react-tel-input .selected-flag {
    top: 1px;
    left: 7px;
    padding: 0 0 0 16px;
}

.react-tel-input .selected-flag .arrow {
  margin-left: 2px;
  background-image: url('../assets/arrow_down.svg');
  border: none !important;
  width: 24px !important;
  height: 24px !important;
  top: 1 !important;
  position: relative;
}


.react-tel-input .selected-flag .arrow::after{
position: absolute;
content: '';
width: 1px;
height: 33px;
background-color: #979797;
right: -10px;
top: -6px;
}
.phone-input .react-tel-input .form-control{
  padding-left: 90px
}
.filter-block-head-with-btn{
    display: flex;
    justify-content: space-between;
}
.gamecard-header-table {
    display: flex;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    margin-left: 3px;
    padding-top: 10px;
}

.gamecard-one {
    flex: 0 0 130px;
    padding-left: 15px;
}

.gamecard-two {
    flex: 0 0 200px;
}

.gamecard-three {
    flex: 1;
}

.gamecard-other-content{
    position: relative;
}
.gamecard-other-content:before {
    content: "";
    position: absolute;
    left: 2px;
    bottom: 24px;
    top: -40px;
    width: 1px;
    background: #D8D8D8;
}
.gamecard-other-content p {
    margin-left: 15px;
    max-width: 71%;
}
.filter-block-head-with-btn h5 {
    color: #000;
}
.view-link{
    width:82px;
    display: block;
    color: #327EE9;
    text-decoration: underline;
}
.choose-dept span {
    filter: brightness(0.5);
}
.goal-process .MuiGrid-container {
    width: unset;
    align-items: center;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-center .MuiGrid-grid-md-6 {
    flex: 0 0 calc(25% - 49px);
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.09);
    border-radius: 12px;
    background-color: #fff;
}

.goal-card-box{
    gap: 46px;
    /* justify-content: space-between; */
}
.MuiPaper-root.MuiCard-root.MuiPaper-outlined.MuiPaper-rounded{
    border:none;
    text-align: center;
}
p.MuiTypography-root.MuiTypography-body2 {
    margin-top: 20px;
    font-size: 21px;
    font-weight: 400;
}
@media (max-width:767px){
    .MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-center .MuiGrid-grid-md-6 {
    flex: 0 0 calc(50% - 49px);
}   
}

@media (max-width:599px){
    .MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-center .MuiGrid-grid-md-6  {
    flex: 0 0 100%;
}   
}

/* .account-link li:first-child {
    background: #EBEBEB;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
} */
/* 
.account-link li:first-child img {
    width: 22px;
} */
/* 
.goal-process p {
    padding: 0 !important;
} */



.header-right .account-link li span {
    font-size: 12px;
    display: block;
    text-align: right;
    color: #84818A;
    opacity: 85%;
    margin: 9px 0 0;
}

.notification-items {
    padding: 0 30px;
    width: 100%;
}

.notification-item h5 {
    margin: 0;
    font-size: 18px;
    color: #000;
}

.notification-item {
    padding: 16px;
    background: #F3F3F3;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    margin-bottom: 24px;
}

 .notification-item h5,
 .notification-content {
    margin-bottom: 12px;
}
.notification-detail {
    display: flex;
    justify-content: space-between;
}

 .notification-detail span {
    font-size: 12px;
    font-weight: 700;
    color: #A9A9A9;
}

.notification-detail a {
    color: #327EE9;
    text-decoration: underline;
    text-transform: capitalize;
}
 .notification-popup-menu {
    position: fixed;
    width: 565px;
    height: 585px;
    right: 0;
    background: #fff;
    top: 87px;
    bottom: 0;
    padding: 30px 0;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    transition: right ease-in 0.5s;
    padding-bottom: 0;
    z-index: 99;
    overflow: auto;
} 
.notification-btn {
    position: absolute;
    bottom: 0;
    z-index: 999;
    left: 0;
    right: 0;
    background: #fff;
    margin-bottom: 0;
    padding: 30px;
    border-radius: 0px 0px 15px 15px;
} 
.admin-profile-popup .notification-popup-menu {
    height: calc(100vh - 100px);
    overflow: auto;
}

.admin-profile-popup {
    width: 100%;
    height: auto;
}

.admin-profile-popup .notification-popup {
    height: auto;
    overflow: unset;
}
.no-records-alert {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 600px;
    justify-content: center;
}
.no-records-alert h3 {
    color: #000;
    
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.no-records-alert h5 {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    max-width: 147px;
}

.accordion-wrapper .MuiAccordionSummary-root{
    padding: 16px 40px;
}

.accordion-wrapper .MuiAccordionSummary-content{
    margin: 0;
    align-items: center;
}
.accordion-wrapper .MuiAccordionSummary-content .MuiTypography-root{
    line-height: 26px;
}
.accordion-wrapper .MuiIconButton-root{
    padding: 0
}
.empContainer{
    display: flex;
    justify-content: space-between;
    margin: 15px 0px;
    word-wrap: anywhere;
}
.employeeContainer{
    display: flex;
    justify-content: space-between;
    margin: 15px 0px;
    word-wrap: anywhere;
}
.empContainer-first{
    width: 70%;
}
.empContainer-second{
    width: 30%;
}
.stepperContainer {
    background-color: #fff;
  }
  
.stepperContainer .MuiStepper-root {
    border: 1px solid #FFCC00;
    background: linear-gradient(#FFF26D, #FFF9E2);
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 10px 80px;
    border-left: none;
    display: flex;
    justify-content: space-between;
    margin: 8px 0 32px;
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: 400;
  }
  
  .stepperContainer .MuiStepConnector-root {
    display: none;
  }
  .rectangleBox{
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 147px;
  }
  .box_image_one{
    position: relative;
    top: -15px;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%22147%22%20height%3D%22164%22%20viewBox%3D%220%200%20147%20164%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M0%2028.984C0%2028.984%2047.0566%200.046875%2073.0061%200.046875C98.9557%200.046875%20146.012%2028.984%20146.012%2028.984V163.619H0V28.984Z%22%20fill%3D%22url(%23paint0_linear_120_9997)%22%2F%3E%3Cdefs%3E%3ClinearGradient%20id%3D%22paint0_linear_120_9997%22%20x1%3D%22143.438%22%20y1%3D%22157.851%22%20x2%3D%22143.438%22%20y2%3D%220.046875%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%3Cstop%20stop-color%3D%22%23FFFDF6%22%2F%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22%23FFCC02%22%2F%3E%3C%2FlinearGradient%3E%3C%2Fdefs%3E%3C%2Fsvg%3E");
  }
  .box_image_two{
    margin: 0px 50px;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%22147%22%20height%3D%22211%22%20viewBox%3D%220%200%20147%20211%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M0.0117188%2037.8983C0.0117188%2037.8983%2047.0683%200.693359%2073.0179%200.693359C98.9674%200.693359%20146.024%2037.8983%20146.024%2037.8983V211H0.0117188V37.8983Z%22%20fill%3D%22url(%23paint0_linear_120_9987)%22%2F%3E%3Cdefs%3E%3ClinearGradient%20id%3D%22paint0_linear_120_9987%22%20x1%3D%22143.449%22%20y1%3D%22203.584%22%20x2%3D%22143.449%22%20y2%3D%220.693359%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%3Cstop%20stop-color%3D%22%23FFFDF6%22%2F%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22%23FFCC02%22%2F%3E%3C%2FlinearGradient%3E%3C%2Fdefs%3E%3C%2Fsvg%3E");
  }
  .box_image_three{
    position: relative;
    top: -10px;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%22147%22%20height%3D%22152%22%20viewBox%3D%220%200%20147%20152%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M0.0234375%2026.9815C0.0234375%2026.9815%2047.08%200.111328%2073.0296%200.111328C98.9791%200.111328%20146.036%2026.9815%20146.036%2026.9815V152H0.0234375V26.9815Z%22%20fill%3D%22url(%23paint0_linear_120_10005)%22%2F%3E%3Cdefs%3E%3ClinearGradient%20id%3D%22paint0_linear_120_10005%22%20x1%3D%22143.461%22%20y1%3D%22146.643%22%20x2%3D%22143.461%22%20y2%3D%220.111328%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%3Cstop%20stop-color%3D%22%23FFFDF6%22%2F%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22%23FFCC02%22%2F%3E%3C%2FlinearGradient%3E%3C%2Fdefs%3E%3C%2Fsvg%3E");
  }
  .rectangle_box_details{
    gap: 5px;
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  .static_container{
    /* background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221183%22%20height%3D%22256%22%20viewBox%3D%220%200%201183%20256%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M1%20222.388C1%20222.388%20381.933%20256%20592%20256C802.067%20256%201183%20222.388%201183%20222.388V66H1V222.388Z%22%20fill%3D%22url(%23paint0_linear_120_9981)%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M0%200C0%200%20485.362%2097.2408%20592.4%2097.2408C699.439%2097.2408%201181.65%200%201181.65%200L1182%20107.063C1182%20107.063%20781.943%20173.562%20590.825%20174.972C399.707%20176.382%200%20107.063%200%20107.063L0%200Z%22%20fill%3D%22url(%23paint1_linear_120_9981)%22%2F%3E%3Cdefs%3E%3ClinearGradient%20id%3D%22paint0_linear_120_9981%22%20x1%3D%221183%22%20y1%3D%2266%22%20x2%3D%221183%22%20y2%3D%22256%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%3Cstop%20stop-color%3D%22%23FFFDF6%22%2F%3E%3Cstop%20offset%3D%220.514961%22%20stop-color%3D%22%23FFE478%22%2F%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22%23FFCC02%22%2F%3E%3C%2FlinearGradient%3E%3ClinearGradient%20id%3D%22paint1_linear_120_9981%22%20x1%3D%221314.37%22%20y1%3D%22236.88%22%20x2%3D%221314.37%22%20y2%3D%2222.6823%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%3Cstop%20stop-color%3D%22%23FFEDA4%22%2F%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22%23FFFDF6%22%2F%3E%3C%2FlinearGradient%3E%3C%2Fdefs%3E%3C%2Fsvg%3E"); */
  }
 
  .react-datepicker{
    border-radius: 16px!important;
    border: 1px solid #EAEAEA !important;
    border-color: #EAEAEA !important;
    
  }
  .highlight-sunday {
   background-color: transparent !important; 
    color: #FF0000 !important;
  }
.control-input {
    border: none;
    outline: none;
    background-color: transparent; 
    padding: 10px; 
    font-size: 16px; 
}

.react-datepicker__header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd; 
}

.react-datepicker__day {
    color: #333; 
}

.react-datepicker__day--selected {
    background-color: #007bff; 
    color: #fff; 
}

.icon-button {
    border: none; 
    padding: 0; 
    margin: 0 5px; 
    cursor: pointer; 
    outline: none; 
    width: 24px;
   height: 24px;
   background: #F8F8F9;
  border-radius: 6px solid #F8F8F9;

  }

  .highlight{
    font-size: 18px !important;
    font-weight: 800;
  }
  
  .showEventDate{
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #000000;

  }
  .react-datepicker__day-names {
   display: flex;
   justify-content: space-around;
   margin-left: -4px;
   margin-top: 10px
  }

  .red {
    color: red;
}

.noEvent{
font-family: Open Sans;
font-size: 16px;
font-weight: 400;
line-height: 22px;
text-align: left;

}
  .control-input .day_type {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    text-align: left !important;
    color: #000000 !important;

  }
.MuiListItem-gutters.first{
    padding:0 !important;
}

.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-image {
    cursor: pointer;
  }
  
  .tooltip-text {
    visibility: hidden;
    background-color: #fff;
    z-index: 1;
    position: absolute;
    opacity: 1;
    transition: 0.3s;
    box-shadow: 0px 4px 18px 0px #00000017;
    border-radius: 8px;
    padding: 12px 19px 12px 8px;
    min-width:180px !important;
    width:100%;
    top: 38px;
    right:0px
  }

  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
   
  }
  .kpi-model{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 420px;
    background-color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    padding: 20px 5px 20px 5px;
    border-radius: 8px;
  }
  .showTempateName{
    word-wrap: anywhere !important;
  }
   