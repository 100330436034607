.landing-header {
    padding: 0 25px;
}

.landing-header-main {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d2d2d2;
    padding: 20px 0;
}

.landing-header-main .navigation {
    padding: 0;
    transition: all 0.3s ease;
}

.landing-header-main .main-nav ul {
    display: flex;
    flex-wrap: wrap;
}

.landing-header-main .main-nav ul li {
    margin: 0;
    padding: 0 10px;
}

.landing-header-main .main-nav ul li a {
    color: #000;
}

.landing-wrapper {
    min-height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.landing-wrapper h4 {
    font-size: 28px;
    color: #000;
    padding-top: 15px;
    margin: 0;
}
@media (max-width: 767px) {
    .menu-icon {
        display: block;
    }

    .header-button {
        margin-right: 40px;
    }

    .landing-header-main .navigation {
        position: fixed;
        top: 0;
        right: -240px;
        bottom: 0;
        width: 240px;
        background: #FDD100;
        padding: 50px 0;
        transition: all 0.3s ease;
    }

    .landing-header-main .main-nav ul {
        flex-direction: column;
    }

    .landing-header-main {
        padding: 10px 0;
    }
    
    .menu-open .navigation {
        right: 0;
    }
}
