.steper-wrapper .center-block-header h3 {
  margin-bottom: 0;
}

.steper-wrapper .MuiStepper-root {
  padding: 40px 0;
  max-width: 200px;
  width: 100%
}

.steper-wrapper .MuiStep-horizontal {
  padding: 0;
}

.steper-wrapper .stepper-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%
}

.steper-wrapper .form-block {
  width: 100%;
}

.steper-wrapper .divider-box {
  position: relative;
}

.steper-wrapper .divider-box::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  border: 1px solid #70707033;
  left: -40px
}

.steper-wrapper .form-field span {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 12px;
  display: block;
}

.steper-wrapper .form-block .control-input,
.steper-wrapper .form-block select {
  padding: 18px 16px;
  border-radius: 15px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  color: #959595;
}

.steper-wrapper .form-block select {
  background: #F3F4F5 !important;
  border: 1px solid #F3F4F5 !important;
}

.steper-wrapper .form-block select:focus {
  box-shadow: none !important;
  border: 1px solid #FDD100 !important;
  background: #fff !important;
}

.second-step-active {
  border-color: #FDD100 !important;
}

.steper-wrapper .MuiStepLabel-iconContainer {
  padding-right: 0px;
}

.center-block-header h3 {
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 0px;
}

.user-profile-setup {
  padding: 0 50px;
}


.form-block .new-password-field {
  padding: 17px 16px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

.form-block .new-password-field::placeholder {
  color: #959595;
}
.grid_overflow{
  height: 343px;
  overflow: auto;
}